.project-card {
    width: 517px;
    height: 354px;
    border: none;
    border-radius: 20px;
}

.project-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-background {
    background-color: #00696c;
    height: 10px;
    width: 100%;
    transform: skewX(-15deg) translate(-5px, 0);
}
.project-body button {
    width: 175px;
}
@media (min-width: 992px) and (max-width: 1200px) {
    .project-card {
        width: 37vw;
    }
}
@media (max-width: 992px) {
    .project-card {
        margin: 0 auto;
    }
}
@media (max-width: 576px) {
    .project-body button {
        margin: 0 auto;
    }

    .project-card{
        height: auto;
    }
}
